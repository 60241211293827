 /* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'


import SEO from "../components/seo"
import Header from "../components/header"

import Footer from "../components/footer"




import Img from 'gatsby-image'

export default ({ data, pageContext: { node, locale, pagePath } }) => {

    let globalData = data.globalData.edges[0].node
    let pageData = data.pageData.edges[0].node
    let {
        img_sliding01,
        img_sliding02, 
        img_sliding03,
        img_t_sliding01,
        img_t_sliding02,
        img_t_sliding03,
    } = pageData.frontmatter


    let page_title_local = pageData.frontmatter[locale + "_title_01"] + " | " + globalData.frontmatter[locale + "_page_title_wooden_doors_prefix"] + " | " + globalData.frontmatter[locale + "_page_title_global_prefix"] + " " + globalData.frontmatter[locale + "_page_title_since_2001_prefix"]  
    let page_meta_description = pageData.frontmatter[locale + "_meta_desc"]
    let page_meta_keywords = pageData.frontmatter[locale + "_meta_keywords"]

    
    return (
        <React.Fragment>
        <SEO 
            description={page_meta_description}
            keywords={page_meta_keywords}
            pathname={pagePath}
            title={page_title_local}
        />
        <Header 
            locale={locale}
            pagePath={pagePath}
        />
        

            <div className="container">


                <div className="row">
                     <div className="col-12">
                        <Img alt={page_meta_description} className="img-fluid" fixed={img_sliding03.childImageSharp.fixed} />                            
                     </div>
                 </div>
                 <div className="row">
  
                     <div className="col-8">
                         <h2>{pageData.frontmatter[ `${locale}_title_02` ]}</h2>
                         <p>{pageData.frontmatter[ `${locale}_text_02` ]}</p>
                     </div>

                     <div className="col-4">
                         <h1>{pageData.frontmatter[ `${locale}_title_01` ]}</h1>
                         <p>{pageData.frontmatter[ `${locale}_text_01` ]}</p>
                     </div>

                 </div>

                 <div className="row">
                     <div className="col-12">
                        <Img alt={page_meta_description} className="img-fluid" fixed={img_sliding01.childImageSharp.fixed} />                            
                     </div>
                 </div>

            </div>




            <Footer 
                locale={locale}
                pagePath={pagePath}
            />

            </React.Fragment>
    
    )
}







export const md_sliding = graphql`query md_sliding {
    pageData:
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "page_sliding_doors"}}}) {
      edges {
        node {
          frontmatter {
            he_alt_img01
            fr_alt_img01
            en_alt_img01
            es_alt_img01
            pr_alt_img01
            ru_alt_img01
            he_alt_img02
            fr_alt_img02
            en_alt_img02
            es_alt_img02
            pr_alt_img02
            ru_alt_img02
            he_alt_img03
            fr_alt_img03
            en_alt_img03
            es_alt_img03
            pr_alt_img03
            ru_alt_img03
            he_meta_keywords
            fr_meta_keywords
            en_meta_keywords
            es_meta_keywords
            pr_meta_keywords
            ru_meta_keywords
            he_meta_desc
            fr_meta_desc
            en_meta_desc
            es_meta_desc
            pr_meta_desc
            ru_meta_desc
            he_title_01
            fr_title_01
            en_title_01
            es_title_01
            pr_title_01
            ru_title_01
            he_title_01
            fr_title_01
            en_title_01
            es_title_01
            pr_title_01
            ru_title_01
            he_title_02
            fr_title_02
            en_title_02
            es_title_02
            pr_title_02
            ru_title_02
            he_title_02
            fr_title_02
            en_title_02
            es_title_02
            pr_title_02
            ru_title_02
            he_title_03
            fr_title_03
            en_title_03
            es_title_03
            pr_title_03
            ru_title_03
            he_title_03
            fr_title_03
            en_title_03
            es_title_03
            pr_title_03
            ru_title_03            
            he_text_01
            fr_text_01
            en_text_01
            es_text_01
            pr_text_01
            ru_text_01
            he_text_01
            fr_text_01
            en_text_01
            es_text_01
            pr_text_01
            ru_text_01
            he_text_02
            fr_text_02
            en_text_02
            es_text_02
            pr_text_02
            ru_text_02
            he_text_02
            fr_text_02
            en_text_02
            es_text_02
            pr_text_02
            ru_text_02
            he_text_03
            fr_text_03
            en_text_03
            es_text_03
            pr_text_03
            ru_text_03
            he_text_03
            fr_text_03
            en_text_03
            es_text_03
            pr_text_03
            ru_text_03
            img_sliding01 {
                childImageSharp {
                  fixed(width: 1200) {
                      ...GatsbyImageSharpFixed
                  }
                }
              }
              img_sliding02 {
                childImageSharp {
                  fixed(width: 1200) {
                      ...GatsbyImageSharpFixed
                  }
                }
              }
              img_sliding03 {
                childImageSharp {
                  fixed(width: 1200) {
                      ...GatsbyImageSharpFixed
                  }
                }
              }
              img_t_sliding01 {
                childImageSharp {
                  fixed(width: 300) {
                      ...GatsbyImageSharpFixed
                  }
                }
              }
              img_t_sliding02 {
                childImageSharp {
                  fixed(width: 300) {
                      ...GatsbyImageSharpFixed
                  }
                }
              }
              img_t_sliding03 {
                  childImageSharp {
                      fixed(width: 300) {
                          ...GatsbyImageSharpFixed
                      }
                  }
              }
            
          }
        }
      }
    }
    globalData: 
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "global_data"}}}) {
        edges {
            node {
                id
                frontmatter {
                    he_page_title_global_prefix
                    he_page_title_since_2001_prefix
                    he_page_title_wooden_doors_prefix
                    he_door_series
                    fr_page_title_global_prefix
                    fr_page_title_since_2001_prefix
                    fr_page_title_wooden_doors_prefix
                    fr_door_series
                    en_page_title_global_prefix
                    en_page_title_since_2001_prefix
                    en_page_title_wooden_doors_prefix
                    en_door_series
                    pr_page_title_global_prefix
                    pr_page_title_since_2001_prefix
                    pr_page_title_wooden_doors_prefix
                    pr_door_series
                    es_page_title_global_prefix
                    es_page_title_since_2001_prefix
                    es_page_title_wooden_doors_prefix
                    es_door_series
                    ru_page_title_global_prefix
                    ru_page_title_since_2001_prefix
                    ru_page_title_wooden_doors_prefix
                    ru_door_series
                }
            }
        }
    }
  }
  
`

